import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Text, View, ScrollView } from 'react-native';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

import SmartButton from "../controls/SmartButton";
import PageVisibility from "../controls/PageVisibility";
import Spinner from "../controls/Spinner";
import { getCommentsApi, postCommentApi } from "../services/CommentService";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

import uuid from "uuid";

const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
};

const Comments = props => {
    const isPageVisible = PageVisibility();
    const timerIdRef = useRef(null);
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);

    const [eventFromNative, setEventFromNative] = useState('initial value');
    const [appMode, setAppMode] = useState('Light');
    const [appModeColor, setAppModeColor] = useState('white');
    const [textModeColor, setTextModeColor] = useState('black');

    const [userProfileId, setUserProfileId] = useState('no');//useState('xyz');
    const stateRefUserProfileId = useRef();

    const [token, setToken] = useState('thanks');//useState('51ae50bd-b78b-497f-ab11-cd4440402128')
    const stateRefToken = useRef();

    const [comment, setComment] = useState('enter a comment');
    const stateRefComment = useRef();

    const [commentList, setCommentList] = useState([]);
    const stateRefCommentList = useRef();

    const [responseComplete, setResponseComplete] = useState(false)
    const stateRefResponseComplete = useRef();

    const [responseMessage, setResponseMessage] = useState('')
    const stateRefResponseMessage = useRef();

    const [newList, setNewList] = useState([]);
    const stateRefNewList = useRef();

    const colors = ["red", "green", "orange", "brown", "pink", "purple", "grey"];
    const stateRefColorMap = useRef({"_ME": "blue"});
    const stateRefAvailableColor = useRef(colors);

    useEffect(() => {
        if (props.route.params.appType == 'W') {
            getComments()
        }

        if (props.route.params.appMode == 'Dark') {
            setAppMode(props.route.params.appMode)
            setAppModeColor('black')
            setTextModeColor('white')
        }

        window.addEventListener('sendprofileandtoken', sentProfileAndTokenHandler)

        const pollingCallback = () => {
            getComments()
        };

        const startPolling = () => {
            // Polling every 10 seconds
            timerIdRef.current = setInterval(pollingCallback, 10000);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPageVisible && isPollingEnabled) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            window.removeEventListener('sendprofileandtoken', sentProfileAndTokenHandler)
            stopPolling();
        }

    }, [sentProfileAndTokenHandler, isPageVisible, isPollingEnabled])

    const sentProfileAndTokenHandler = useCallback(
        (e) => {
            setEventFromNative(e.detail.userProfileId)
            setUserProfileId(e.detail.userProfileId)
            setToken(e.detail.token)
            stateRefUserProfileId.current = e.detail.userProfileId
            stateRefToken.current = e.detail.token
            getComments()
        },
        [setEventFromNative, setUserProfileId, setToken, setResponseMessage]
    )

    const onChangeInfo = useCallback((userProfileId) => {
        getComments()
    }, []);

    const getComments = () => {
        if (props.route.params.appType == 'W') {
            setUserProfileId('');
            stateRefUserProfileId.current = 'xyz';
            setToken('');
            stateRefToken.current = '9fcde341-3da1-4d45-9d62-0d98ef83ce2f';//'51ae50bd-b78b-497f-ab11-cd4440402128';
        }

        console.log("get comments " + stateRefUserProfileId.current + " :: " + stateRefToken.current);
        getCommentsApi(stateRefUserProfileId.current, stateRefToken.current)
            .then(result => {
                var jsonResult = result;
                if ( jsonResult['comments']) {
                    var currentLength = 0 
                    if (stateRefCommentList.current && stateRefCommentList.current.length) {
                        currentLength = stateRefCommentList.current.length;
                    } 
                    if (jsonResult['comments'].length > currentLength) {
                        setCommentList([])
                        stateRefCommentList.current = []
                        console.log("adding new comment")
                        jsonResult['comments'].map((i) => addCommentToList(i));
                    } else {
                        console.log("No new comments.")
                    }
                } 
            }).catch(error => {
                stateRefResponseMessage.current = 'Failed to get comments.';
                console.log("response comments error is -> ");
                console.error(error);
                setIsPollingEnabled(false);
            });
        console.log("Click get comments " + stateRefUserProfileId.current + "::" + stateRefToken.current);
    }

    const addCommentToList = (item) => {
        if (stateRefColorMap.current[item['userProfileId']] == undefined) {
            const randomColor = Math.floor(Math.random() * 5)
            stateRefColorMap.current[item['userProfileId']] = stateRefAvailableColor.current[randomColor]
            console.log("picked color " + stateRefColorMap.current[item['userProfileId']] + " for " + item['userProfileId'])
            stateRefAvailableColor.current = [...stateRefAvailableColor.current.slice(0, randomColor), ...stateRefAvailableColor.current.slice(randomColor + 1)]
            console.log("available colors: " + stateRefAvailableColor.current)
            if (stateRefAvailableColor.current.length <= 0) {
                stateRefAvailableColor.current = colors
            }
        } 

        if (stateRefCommentList.current == undefined) {
            console.log("not updated")
            setCommentList([{
                id: item['commentId'],
                comment: item['comment'],
                displayName: item['displayName'].substring(0,3),
                fullDisplayName: item['displayName'],
                uid: item['userProfileId']
            },
            ])
            stateRefCommentList.current = [{
                id: item['commentId'],
                comment: item['comment'],
                displayName: item['displayName'].substring(0,3),
                fullDisplayName: item['displayName'],
                uid: item['userProfileId']
            },
            ]
        } else {
            setCommentList([{
                id: item['commentId'],
                comment: item['comment'],
                displayName: item['displayName'].substring(0,3),
                fullDisplayName: item['displayName'],
                uid: item['userProfileId']
            },
            ...stateRefCommentList.current
            ])
            stateRefCommentList.current = [{
                id: item['commentId'],
                comment: item['comment'],
                displayName: item['displayName'].substring(0,3),
                fullDisplayName: item['displayName'],
                uid: item['userProfileId']
            },
            ...stateRefCommentList.current
            ]
        }
    }

    const newComment = (comment) => {
        if (stateRefNewList.current == undefined) {
            setNewList([{
                id: uuid.v4(),
                comment: stateRefComment.current.value,
                displayName: 'ME',
                fullDisplayName: 'this is me',
                uid: '_ME'
            }]);
            stateRefNewList.current = [{
                id: uuid.v4(),
                comment: stateRefComment.current.value,
                displayName: 'ME',
                fullDisplayName: 'this is me',
                uid: '_ME'
            },
            ];
            console.log("created new list: " + JSON.stringify(stateRefNewList.current));
        } else {
            setNewList([{
                id: uuid.v4(),
                comment: stateRefComment.current.value,
                displayName: 'ME',
                fullDisplayName: 'this is me',
                uid: '_ME'
            },
            ...stateRefNewList.current
            ]);
            stateRefNewList.current = [{
                id: uuid.v4(),
                comment: stateRefComment.current.value,
                displayName: 'ME',
                fullDisplayName: 'this is me',
                uid: '_ME'
            },
            ...stateRefNewList.current
            ];
            console.log("added to new list: " + JSON.stringify(stateRefNewList.current));
        }
    }

    const sendComment = () => {
        stateRefNewList.current.forEach((item) => {
            console.log("sending: " + JSON.stringify(item));
            postCommentApi(stateRefUserProfileId.current, stateRefToken.current, item['id'], item['comment'])
            .then(result => {
                var jsonResult = result;
                console.log("Result: " + JSON.stringify(jsonResult));
                setComment('')
                stateRefComment.current.value = ''
            }).catch(error => {
                setResponseMessage('Failed to send comments.');
                stateRefResponseMessage.current = 'Failed to send comments.';
                console.log("response comments send error is -> ");
                console.error(error);
            });
        });
        setNewList([])
        stateRefNewList.current = []
    }

    const addComment = (event) => {
        newComment(stateRefComment.current.value);
        if (stateRefCommentList.current == undefined) {
            console.log("not updated")
            setCommentList(newList)
            stateRefCommentList.current = newList
            sendComment()
        } else {
            setCommentList([...stateRefNewList.current,
                ...stateRefCommentList.current
            ])
            stateRefCommentList.current = [...stateRefNewList.current,
                ...stateRefCommentList.current
            ]
            sendComment()
        }
    }

    const ResponseText = () => {
        return (<Text>{responseMessage}</Text>);
    }

    const smartTextBorder = (uid) => {
        const chosenColor = (uid === stateRefUserProfileId.current || uid === '_ME') ? "blue" : stateRefColorMap.current[uid]
        return {
        borderWidth: 1,
        borderColor: chosenColor,
        borderRadius: 50,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 12,
        backgroundColor: chosenColor,
        color: "white",
        fontWeight: "bold"
    }};

    const UiCommentList = () => {
        return (
            <List style={{ maxHeight: 300, overflow: 'auto', backgroundColor: appModeColor }}>
                {commentList.map((item) => (
                    <ListItem key={item.id}>
                        <ListItemIcon>
                            <Tooltip title={item.fullDisplayName}>
                                {/* <Text style={(item.uid === stateRefUserProfileId.current || item.uid === '_ME') ? myTextBorder : textBorder}>{item.displayName}</Text> */}
                                <Text style={smartTextBorder(item.uid)}>{item.displayName}</Text>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText style={{ color: textModeColor, backgroundColor: appModeColor }}>{item.comment}</ListItemText>
                    </ListItem>
                ))}
            </List>
        )
    }

    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: appModeColor
            }}>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: appModeColor }}>
                <div>
                    <ResponseText />
                </div>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <TextField
                        id="comment"
                        name="comment"
                        label="enter comment"
                        inputRef={stateRefComment}
                        variant="filled"
                        inputProps={{ autoCapitalize: 'none' }}
                        sx={{
                            color: "white",
                            backgroundColor: "white",
                            borderColor: "blue",
                        }}
                    />
                    &nbsp;&nbsp;
                    {/* <SmartButton value="Submit" onclick={addComment} /> */}
                    <Button variant="contained" endIcon={<SendIcon />} onClick={addComment}>Send</Button>
                </div>
                <p></p>
                <div>

                </div>
                <p></p>
                <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
                    <nav aria-label="dynamic list">
                        <UiCommentList />
                    </nav>
                </Paper>
            </Box>
        </View>
    );
}


export default Comments