import React, { Component } from 'react';
import { Button, Text, View } from 'react-native';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";
import Spinner from "../controls/Spinner";
import { postInviteApi } from "../services/AuthService";

const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "50px",
};

const spinnerStyle = {
  width: "5%",
  height: "5%",
  justifyContent: "center",
  alignItems: "center",
};

const sleep = ms => new Promise(r => setTimeout(r, ms));

class AcceptInvite extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.value ? props.value : '',
      inviteToken: props.value ? props.value : '',
      token: '',
      accepted: false,
      userProfileId: '',
      appType: props.route.params.appType ? props.route.params.appType : 'W',
      loading: false,
      responseComplete: false,
      responseMessage: '',
      appMode: props.route.params.appMode ? props.route.params.appMode : 'Light',
      appModeColor: (props.route.params.appMode == 'Dark') ? 'black' : 'white',
      textModeColor: (props.route.params.appMode == 'Dark') ? 'white' : 'black'
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  sendMessage() {
    var customBodyMessage = '{"userProfileId\": "' + this.state.userProfileId + '"' +
      ', "token": "' + this.state.token + '"' +
      ', "email": "' + this.state.email + '"' + 
      '}';
    window.webkit.messageHandlers.jsMessageHandler.postMessage(customBodyMessage);
  }

  clickApp() {
    console.log("Click App " + this.state.email);
    console.log("Data " + this.state);
  }

  clickInvite() {
    console.log("accpeted invite");
    this.state.loading = true;
    this.forceUpdate();
    postInviteApi(this.state.email, this.state.inviteToken)
        .then(result => {
          var jsonResult = result[0];
          console.log("Result: " + JSON.stringify(jsonResult));
          console.log("Result token: " + jsonResult.token);
          console.log("Result profile: " + jsonResult.userProfileId);
          this.state.token = result[1];
          this.state.userProfileId = jsonResult.userProfileId;
          this.state.accepted = true;
          this.state.loading = false;
          this.state.responseComplete = true;
          this.state.responseMessage = 'Invite Accepted.';
          this.forceUpdate();
          this.sendMessage();
        }).catch(error => {
          this.state.loading = false;
          this.state.responseComplete = true;
          this.state.responseMessage = 'Something is wrong with your invite information.  Please try again.';
          console.log("response password error is -> ");
          console.error(error);
          this.forceUpdate();
        });
    console.log("Click App " + this.state.email);
  }
  
  async clickSpinner() {
    console.log("loading");
    this.state.loading = true;
    this.forceUpdate();
    await sleep(3000)
    this.state.loading = false;
    console.log("loaded");
    this.forceUpdate();
  }

render() {
    let buttonHome;
    let buttonBack;
    let responseText;
    
    let webTextStyle = {
      width: "25%",
      justifyContent: "center",
      alignItems: "center",
    };

    let mobileTextStyle = {
      width: "50%",
      justifyContent: "center",
      alignItems: "center",
      color: this.state.textModeColor ? this.state.textModeColor : 'black'
    };

    if (this.state.appType == 'W') {
      buttonHome = <Button title="Go to Home" onPress={() => navigation.navigate('SignIn')} />
      buttonBack = <Button title="Go back" onPress={() => navigation.goBack()} />
      responseText = <Text style={webTextStyle}>{this.state.responseMessage}</Text>
    } else {
      responseText = <Text style={mobileTextStyle}>{this.state.responseMessage}</Text>
    }

    let spinner;

    if(this.state.loading) {
      spinner = <Spinner/>;
    }
  
    if (this.state.accepted) {
      return (
          <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: this.state.appModeColor
            }}>
            {responseText}
            <p></p>
            {buttonHome}
            {buttonBack}
          </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: this.state.appModeColor
          }}>
          {responseText}
          <p/>
          <div className={spinnerStyle}>
            { spinner }
          </div>
          <div>
            <TextField
              id="email"
              name="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleChange}
              variant="filled"
              inputProps={{ autoCapitalize: 'none' }}
              sx={{
                color: "white",
                backgroundColor: "white",
                borderColor: "blue",
              }}
            />
          </div>
          <div>
            <TextField
              id="inviteToken"
              name="inviteToken"
              label="Invite Token"
              value={this.state.inviteToken}
              onChange={this.handleChange}
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderColor: "blue",
              }}
            />
          </div>
          <p></p>
          <SmartButton value="Accept" onclick={() => this.clickInvite()} />
          <p></p>
          <div style={divStyle}>
            {buttonHome}
            {buttonBack}
          </div>
        </View>
      );
    }
  }
}

export default AcceptInvite;