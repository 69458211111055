import {API_URL, API_ERROR_ALERT} from "../services/BaseService";
import uuid from "uuid";

const serverURL = API_URL();


export const getExamplePath = () => {
    return fetch(serverURL + '/examplepath')
        .then((response) => response.text())
        .then((text) => {
            console.log("response text: " + text);
        })
        .catch(error => {
            console.log("response sign in error is -> ");
            console.error(error);
        });
}

export const  getSignInApi = async (email, password) => {
    console.log("signing in " + email + ", " + password);
    return fetch(serverURL + '/sizzle/signin', {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "username": email, "password": password }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return [responseBody, responseToken];
        })
        .catch(error => {
            console.log("response sign in error is -> ");
            console.error(error);
        });
};

export const  postSignUpApi = async (email, firstName, lastName, displayName) => {
    const userProfileId = uuid.v4();
    console.log("signing up " + 
        email + ", " + 
        firstName + ", " + 
        lastName + ", " + 
        displayName + ", " + 
        userProfileId);
    return fetch(serverURL + '/sizzle/register', {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ 
                "email": email, 
                "firstName": firstName,
                "lastName": lastName,
                "displayName": displayName,
                "userProfileId": userProfileId,
             }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return [responseBody, responseToken];
        })
        .catch(error => {
            console.log("response sign in error is -> ");
            console.error(error);
        });
};

export const  postInviteApi = async (email, inviteToken) => {
    console.log("accepting invite " + email + ", " + inviteToken);
    return fetch(serverURL + '/sizzle/invite', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "email": email, "invitetoken": inviteToken }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return [responseBody, responseToken];
        })
        .catch(error => {
            console.log("response invite error is -> ");
            console.error(error);
        });
};

export const  postPasswordApi = async (userProfileId, password, token) => {
    console.log("password update for " + userProfileId);
    return fetch(serverURL + '/sizzle/setpassword', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "userprofileid": userProfileId,
                "apptoken": token
            },
            body: JSON.stringify({ "userProfileId": userProfileId, "password": password }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return [responseBody, responseToken];
        })
        .catch(error => {
            console.log("response set password error is -> ");
            console.error(error);
        });
};

export const  postDeleteAccountApi = async (userProfileId, token) => {
    console.log("delete account for " + userProfileId);
    return fetch(serverURL + '/sizzle/deleteaccount', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "userprofileid": userProfileId,
                "apptoken": token
            },
            body: JSON.stringify({ "userProfileId": userProfileId }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return true;
        })
        .catch(error => {
            console.log("response set password error is -> ");
            console.error(error);
            return false;
        });
};

export const  postUserInfoApi = async (userProfileId, token, displayName, inviteToken) => {
    console.log("user info update for " + userProfileId);
    console.log("user info update for " + displayName);
    return fetch(serverURL + '/sizzle/setuserinfo', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "userprofileid": userProfileId,
                "apptoken": token
            },
            body: JSON.stringify({ "userProfileId": userProfileId, "displayName": displayName, "inviteToken": inviteToken }),
        })
        .then(async (response) => {
            var responseToken = response.headers.get('apptoken');
            var responseBody = await response.json();
            return [responseBody, responseToken];
        })
        .catch(error => {
            console.log("response set user info error is -> ");
            console.error(error);
        });
};