import {API_URL, API_ERROR_ALERT} from "../services/BaseService";
import uuid from "uuid";

const serverURL = API_URL();

export const  getCommentsApi = async (userProfileId, token) => {
    console.log("get comments api " + userProfileId + " :: " + token);
    return fetch(serverURL + '/sizzle/comments', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "userprofileid": userProfileId,
                "apptoken": token
            }
        })
        .then(async (response) => {
            var responseBody = await response.json();
            return responseBody;
        })
        .catch(error => {
            console.log("response comments api error is -> ");
            console.error(error);
        });
};

export const  postCommentApi = async (userProfileId, token, commentId, comment) => {
    console.log("post comment for " + userProfileId + ":" + JSON.stringify({ "commentId": commentId, "comment": comment }));
    return fetch(serverURL + '/sizzle/comment', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "userprofileid": userProfileId,
                "apptoken": token
            },
            body: JSON.stringify({ "commentId": commentId, "comment": comment }),
        })
        .then(async (response) => {
            var responseBody = await response.json();
            return true;
        })
        .catch(error => {
            console.log("response set password error is -> ");
            console.error(error);
            return false;
        });
};
