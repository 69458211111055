
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Text, View } from 'react-native';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import PinIcon from '@mui/icons-material/Pin';

import { postUserInfoApi, postDeleteAccountApi } from "../services/AuthService";

const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  };

const darkTitleStyle = {
  fontSize: 20,
  fontWeight: 'bold',
  color: 'white',
  justifyContent: "center",
  alignItems: "center",
  textAlign: 'center',
  marginLeft: "20px"
};

const lightTitleStyle = {
  fontSize: 20,
  fontWeight: 'bold',
  color: 'black',
  justifyContent: "center",
  alignItems: "center",
  textAlign: 'center',
  marginLeft: "20px"
};

const darkSubtitleStyle = {
  fontSize: 12,
  color: 'white'
};

const lightSubtitleStyle = {
  fontSize: 12,
  color: 'black'
};

const errorStyle = {
  fontSize: 14,
  color: 'red'
};

const numbersRe = /^[0-9\b]+$/;

const UserInfoSettings = props => {
    const [eventFromNative, setEventFromNative] = useState('initial value')
    const [appMode, setAppMode] = useState('Light');
    const [appModeColor, setAppModeColor] = useState('white');
    const [textModeColor, setTextModeColor] = useState('black');

    const [userProfileId, setUserProfileId] = useState('xyz')
    const [email, setEmail] = useState('me@sizzle.com')//useState('sizzleauthtest@sizzle.com')
    const [token, setToken] = useState('9fcde341-3da1-4d45-9d62-0d98ef83ce2f')
    const [displayName, setDisplayName] = useState('nope')
    const [accepted, setAccepted] = useState(false)
    const [responseComplete, setResponseComplete] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const stateRefUserProfileId = useRef();
    const stateRefEmail = useRef();
    const stateRefToken = useRef();
    const stateRefDisplayName = useRef();
    const stateRefInviteToken = useRef();

    useEffect(() => {
      if (props.route.params.appMode == 'Dark') {
        setAppMode(props.route.params.appMode)
        setAppModeColor('black')
        setTextModeColor('white')
      }

        window.addEventListener('userinfoprofileandtoken', userInfoProfileAndTokenHandler)

        return () =>
            window.removeEventListener('userinfoprofileandtoken', userInfoProfileAndTokenHandler)
    }, [userInfoProfileAndTokenHandler])

    const userInfoProfileAndTokenHandler = useCallback(
        (e) => {
            setEventFromNative(e.detail.userProfileId)
            setUserProfileId(e.detail.userProfileId)
            setToken(e.detail.token)
            stateRefUserProfileId.current = e.detail.userProfileId
            stateRefToken.current = e.detail.token
            
            setEmail(e.detail.email)
            stateRefEmail.current = e.detail.email
        },
        [setEventFromNative, setUserProfileId, setToken, setEmail]
    )

    const updatedToken = (token) => {
        window?.webkit?.messageHandlers?.USERINFO_BRIDGE?.postMessage({
            message: "Updated token. " + token,
        })
    }

    const clickSetUserInfo = () => {
        console.log("setting display name");
  
        if (stateRefDisplayName.current.value != "" || stateRefInviteToken.current.value != "") {
          if (stateRefInviteToken.current.value != "" && stateRefInviteToken.current.value.length < 4) {
            setResponseComplete(true);
            setResponseMessage('Invite token must be at least 4 characters.');
          }
          postUserInfoApi(stateRefUserProfileId.current, stateRefToken.current, stateRefDisplayName.current.value, stateRefInviteToken.current.value)
            .then(result => {
              var jsonResult = result[0];
              setResponseComplete(true);
              setResponseMessage('Successfully updated user info.');
            }).catch(error => {
              setResponseComplete(true);
              setResponseMessage('Failed to update user info.');
            });
        } 
      }
    
    const clickDeleteAccount = () => {
        console.log('deleting uid: ' + stateRefUserProfileId.current)
        postDeleteAccountApi(stateRefUserProfileId.current, stateRefToken.current)
        .then(result => {
          var jsonResult = result[0];
          console.log('delete acct: ' + result);
          setResponseComplete(true);
          setResponseMessage('Successfully deleted account.');
        })
        .catch(error => {
          setResponseComplete(true);
          setResponseMessage('Failed to delete account.');
          console.log("response delete account error is -> ");
          console.error(error);
        });
        setResponseComplete(true);
        setResponseMessage('deleting account ' + uid);
        console.log("deleting account");
    }

    const BackButton = () => {
      if (props.route.params.appType == 'W') {
        return (<Button title="Go back" onClick={() => navigation.goBack()} />)
      }
    }

    const HomeButton = () => {
      if (props.route.params.appType == 'W') {
        return (<Button title="Go to Home" onClick={() => navigation.navigate('SignIn')} />)
      }
    }

    const ResponseText = () => {
      if (responseComplete) {
        return (<Text style={ (appMode == 'Dark') ? darkTitleStyle : lightTitleStyle }>{responseMessage}</Text>);
      }
    }

    const ErrorText = () => {
      return (<Text style={errorStyle}>{responseMessage}</Text>);
    }

    const BothBackButton = () => {
      return (<Button variant="contained" onClick={() => navigation.navigate('UserInfoSettings')}>Back</Button>);
    }

    const TitleText = () => {
      if (!responseComplete) {
        return (
          <Text style={ (appMode == 'Dark') ? darkTitleStyle : lightTitleStyle }>Set Display Name, Invite Token or Delete Account</Text>
        );
      }
    }

    const SubtitleText = () => {
      if (!responseComplete) {
        return (
          <Text style={ (appMode == 'Dark') ? darkSubtitleStyle : lightSubtitleStyle }>* Leave field blank if you do not want to update it</Text>
        );
      }
    }

    const UserText = () => {
      if (!responseComplete) {
        return (
          <Text style={ (appMode == 'Dark') ? darkTitleStyle : lightTitleStyle }>{email}</Text>
        );
      }
    }

    const DebugText = () => {
      if (!responseComplete) {
        return (
          <Text style={ (appMode == 'Dark') ? darkSubtitleStyle : lightSubtitleStyle }>{userProfileId} - {token.substring(0,3)}</Text>
        );
      }
    }

    if (responseComplete) {
      return (
          <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: appModeColor
          }}>
            <ResponseText sx={{color: textModeColor}}/>
            <p/>
            <BothBackButton />
          </View>
      )
    } else {
      return (
          <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: appModeColor
          }}>
          <TitleText />
          <SubtitleText />
          &nbsp;&nbsp;
          <UserText />
          <ErrorText />
          <div style={divStyle}>
            <TextField
                id="displayName"
                name="displayName"
                label="display name"
                inputRef={stateRefDisplayName}
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderColor: "blue",
                }}
              />  
          </div>
          <div style={divStyle}>
            <TextField
                id="inviteToken"
                name="inviteToken"
                label="reset invite token"
                inputRef={stateRefInviteToken}
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderColor: "blue",
                }}
              />  
          </div>
          &nbsp;&nbsp;
          <div>
            <Button variant="contained" endIcon={<SendIcon />} onClick={clickSetUserInfo}>Send</Button>
            &nbsp;&nbsp;
            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={clickDeleteAccount}>Delete Account</Button>
          </div>
          <p/>
          <div>
            <BackButton />
            &nbsp;&nbsp;
            <HomeButton />
          </div>
        </View>
      )
    }
}

export default UserInfoSettings