import {REACT_APP_DEV_MODE, REACT_APP_API_ENDPOINT_DEV, REACT_APP_API_ENDPOINT_PROD} from "@env";

export const API_URL = () => {
    console.log('Mode ' + JSON.stringify(process.env));
    console.log('Dev Mode: ' + REACT_APP_DEV_MODE);

    if (process.env.NODE_ENV === 'development') {
        return REACT_APP_API_ENDPOINT_DEV;
    } else {
        return REACT_APP_API_ENDPOINT_PROD;
    } 
    return 'error';
};

export const API_ERROR_ALERT = (input) => {
    alert('Server Error: ' + input);
 };
