// Home.js, just a name of the stack navigation
import React, { Component } from 'react';
import { Button, Text, View } from 'react-native';
// import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";
import { postSignUpApi } from "../services/AuthService";

const vStackStyle = {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
};

const hStackStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
};

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: props.value ? props.value : '',
            firstName: '', // props.value,
            lastName: '', // props.value,
            displayName: '', // props.value,
            appType: props.route.params.appType ? props.route.params.appType : 'W',
            appMode: props.route.params.appMode ? props.route.params.appMode : 'Light',
            appModeColor: (props.route.params.appMode == 'Dark') ? 'black' : 'white',
            textModeColor: (props.route.params.appMode == 'Dark') ? 'white' : 'black'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        // console.log("State: " + this.state);
        // console.log("Entered: " + event.target.value);
        // console.log("Setting: " + event.target.name);
        this.setState({
            [event.target.name]: event.target.value,
        });
        // console.log("Handle Change " + this.state.email);
    }

    clickApp() {
        console.log("Click App " + this.state.email);
        console.log("Data " + this.state);
    }

    clickSignUp() {
        postSignUpApi(this.state.email, this.state.firstName, this.state.lastName, this.state.displayName)
        .then(result => {
            console.log("Result body: " + JSON.stringify(result[0]));
            console.log("Result token: " + result[1]);
            this.state.token = result[1];
            this.state.loggedin = true;
            this.forceUpdate();
        } );
    }

    render() {

        let titleStyle = {
            fontSize: 20,
            fontWeight: 'bold',
            color: this.state.textModeColor ? this.state.textModeColor : 'black'
        };

        let buttonHome;
        let buttonBack;
        if (this.state.appType == 'W') {
          buttonHome = <Button title="Go to Home" onPress={() => navigation.navigate('SignIn')} />
          buttonBack = <Button title="Go back" onPress={() => navigation.goBack()} />
        }
        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: this.state.appModeColor
                }}>
                <Text style={titleStyle} >Sign Up</Text>

                <div style={vStackStyle}>
                    <TextField
                        id="email"
                        name="email"
                        label="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        variant="filled"
                        sx={{
                            color: "white",
                            backgroundColor: "white",
                            borderColor: "blue",
                        }}
                    />
                    <p/>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        variant="filled"
                        sx={{
                            color: "white",
                            backgroundColor: "white",
                            borderColor: "blue",
                        }}
                    />
                    <p/>
                    <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        variant="filled"
                        sx={{
                            color: "white",
                            backgroundColor: "white",
                            borderColor: "blue",
                        }}
                    />
                    <p/>
                    <TextField
                        id="displayName"
                        name="displayName"
                        label="Display Name"
                        value={this.state.displayName}
                        onChange={this.handleChange}
                        variant="filled"
                        sx={{
                            color: "white",
                            backgroundColor: "white",
                            borderColor: "blue",
                        }}
                    />
                </div>
                <div style={hStackStyle}>
                    <SmartButton id="register-button" value="Register" onclick={() => this.clickSignUp()} />
                    &nbsp;&nbsp;
                    {buttonHome}
                    &nbsp;&nbsp;
                    {buttonBack}
                </div>
            </View>
        );
    }
}

export default SignUp;