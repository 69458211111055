import React, { Component } from 'react';
import Button from '@mui/material/Button';

class SmartButton extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        id: props.id,
        value: props.value,
        click: props.onclick,
      }
    }
    render() { 
      return <Button variant="contained" id={this.state.id} onClick={() => this.state.click()}>{this.state.value}</Button>
    }
  }

  export default SmartButton;
