// Home.js, just a name of the stack navigation
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Button, Text, View } from 'react-native';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";
import {API_URL, API_ERROR_ALERT} from "../services/BaseService";

import { getSignInApi } from "../services/AuthService";


const serverURL = API_URL();

const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    pading: 10,
};

class ExplorePages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: 'test@sizzle.com',
            password: 'yyyyyyy',
            token: '',
            loggedIn: false,
        };
        this.handleChange = this.handleChange.bind(this);

        console.log('Server URL: ' + serverURL);
        // API_ERROR_ALERT('test');
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    sendMessage() {
        window.webkit.messageHandlers.jsMessageHandler.postMessage(this.state.token);
    }

    clickExamplePath() {
        console.log("clicked sign in");
        const getExamplePathApi = () => {
            console.log("getting exmaple path");
            return fetch(serverURL + '/examplepath')
              .then((response) => response.text())
              .then((text) => {
                console.log("response text: " + text);
              })
              .catch(error => {
                console.log("response sign in error is -> ");
                console.error(error);
              });
          };
        // const getExamplePathApi = async () => {
        //     try {
        //         console.log("getting exmaple path")
        //         const response = await fetch('https://q97lts1fc0.execute-api.us-east-1.amazonaws.com/Prod/examplepath');
        //         const text = await response.text;
        //         console.log("Text: " + text)
        //     } catch (error) {
        //         console.error(error);
        //     } finally {
        //     }
        // };
        getExamplePathApi();
        console.log("Click App " + this.state.email);
        console.log("Data " + this.state);
    }

    clickSignIn() {
        console.log("clicked sign in");
        
        // const getSignInApi = () => {
        //     console.log("signing in {}");
        //     return fetch(serverURL + '/sizzle/signin', {
        //         method: "POST", // or 'PUT'
        //         headers: {
        //           "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify({"username": this.state.email, "password": this.state.password}),
        //       })
        //       .then(result => result.json())
        //       .then(data => {
        //         console.log("response user profile id: " + data.userProfileId);
        //       })
        //       .catch(error => {
        //         console.log("response sign in error is -> ");
        //         console.error(error);
        //       });
        //   };
        getSignInApi(this.state.email, this.state.password)
            .then(result => {
                console.log("Result body: " + JSON.stringify(result[0]));
                console.log("Result token: " + result[1]);
                this.state.token = result[1];
                this.state.loggedin = true;
                this.forceUpdate();
            } );
        //sendMessage();
    }

    render() {
        if (this.state.loggedin) {
            return (
                 <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Text>Logged in</Text>
                </View>
            );
        } else {
            return (
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div style={divStyle}>
                        <Text>Logging in</Text>
                        <Box m={2}>
                            <TextField
                                testID={'emailTestID'}
                                id="email"
                                name="email"
                                label="email"
                                value={this.state.stretchcategory}
                                onChange={this.handleChange}
                                variant="filled"
                                defaultValue="test@sizzle.com"
                                sx={{
                                    color: "white",
                                    backgroundColor: "white",
                                    borderColor: "blue",
                                }}
                            />
                        </Box>
                        <Box m={2}>
                            <TextField
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={this.state.stretchcategory}
                                onChange={this.handleChange}
                                defaultValue="yyyyyyy"
                                sx={{
                                    color: "white",
                                    backgroundColor: "white",
                                    borderColor: "blue",
                                }}
                            />
                        </Box>
                        <SmartButton testid={'signInSubmitTestID'} id="sign-in-button" value="Sign In" onclick={() => this.clickSignIn()} />
                    </div>
                    <div style={divStyle}>
                        <SmartButton value="Example" onclick={() => this.clickExamplePath()} />
                        <Box m={2}><Button title="Forgot Password" onPress={() => this.props.navigation.navigate('Forgot')} /></Box>
                        <Box m={2}><Button title="Set Password" onPress={() => this.props.navigation.navigate('Password')} /></Box>
                        <Box m={2}><Button title="Sign Up" onPress={() => this.props.navigation.navigate('SignUp')} /></Box>
                        <Box m={2}><Button title="Accept Invite" onPress={() => this.props.navigation.navigate('AcceptInvite')} /></Box>
                        <Box m={2}><Button title="Manage Password" onPress={() => this.props.navigation.navigate('ManagePassword')} /></Box>
                        <Box m={2}><Button title="Comment Page" onPress={() => this.props.navigation.navigate('CommentPage')} /></Box>
                        <Box m={2}><Button title="Comments" onPress={() => this.props.navigation.navigate('Comments')} /></Box>
                    </div>
                    <div style={divStyle}>
                        {/* <a class="yourButton" href="inapp://capture" style={linkButtonStyle}>Download Stretches</a> */}
                        <SmartButton value="Load App" onclick={() => this.sendMessage()} />
                        
                        <SmartButton value="to app" onclick={(e) => { window.location.href='inapp://capture'; } } />
                        <br />
                    </div>
                </View>
            );
        }
    }
}

export default ExplorePages;