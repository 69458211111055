import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';

import * as React from 'react';
import { useState } from 'react'

import { NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AboutScreen from "./pages/AboutScreen";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Forgot from "./pages/Forgot";
import UserInfoSettings from "./pages/UserInfoSettings";
import AcceptInvite from "./pages/AcceptInvite";
import SampleComponent from "./pages/SampleComponent";
import ManagePassword from "./pages/ManagePassword";
import Comments from "./pages/Comments";
import ExplorePages from "./pages/ExplorePages";

const Stack = createNativeStackNavigator();

const headerTitle = process.env.NODE_ENV === 'development' ? ' - ' + process.env.REACT_APP_DEV_MODE : '';

const linking = {
  prefixes: ['inapp://', 'http://localhost:19006/', 'http://127.0.0.1:3000/', 'https://5c66oc09ic.execute-api.us-east-1.amazonaws.com/Prod', 'https://xptbyfrlal.execute-api.us-east-1.amazonaws.com/Prod']
};

const MyTheme = {
  dark: false,
  colors: {
    primary: 'rgb(255, 45, 85)',
    background: 'rgb(242, 242, 242)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(28, 28, 30)',
    border: 'rgb(199, 199, 204)',
    notification: 'rgb(255, 69, 58)',
  },
};

const MyThemeLight = {
  dark: false,
  colors: {
    primary: 'rgba(0, 0, 0, 0.87)',
    background: '#FFFFFF', //'rgb(242, 242, 242)',
    border: '#FFFFFF', //'rgb(199, 199, 204)',
  },
};

const MyThemeDark = {
  dark: false,
  colors: {
    primary: 'rgba(0, 0, 0, 0.87)',
    background: '#000000', //'rgb(242, 242, 242)',
    border: '#000000', //'rgb(199, 199, 204)',
    text: '#FFFFFF'
  },
};

export const navigationRef = createNavigationContainerRef()


export default function App() {
  const [routeName, setRouteName] = useState();
  const [params, setParams] = useState({'appMode': 'Dark'});
  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        setRouteName(navigationRef.getCurrentRoute().name)
        setParams(navigationRef.getCurrentRoute().params)
      }}
      onStateChange={async () => {
        const previousRouteName = routeName;
        const currentRouteName = navigationRef.getCurrentRoute().name;
        setRouteName(currentRouteName);
        setParams(navigationRef.getCurrentRoute().params)
      }}
      linking={linking} 
      theme={(params == undefined || params.appMode == undefined || params.appMode == 'Light') ? MyThemeLight : MyThemeDark } 
      fallback={<Text color="blue" size="large">Failed to load</Text>}>
      <Stack.Navigator>
        <Stack.Screen name="SignIn" component={SignIn} options={{ title: 'Sign In' + headerTitle}}/>
        <Stack.Screen name="SignUp" component={SignUp} options={{ title: 'Sign Up' + headerTitle}} initialParams={{'appType':'W'}} />
        <Stack.Screen name="Forgot" component={Forgot} options={{ title: 'Forgot' + headerTitle}} />
        <Stack.Screen name="ManagePassword" component={ManagePassword} options={{ title: 'ManagePassword' + headerTitle}} initialParams={{'appType':'W', 'appMode':'Light'}} />
        <Stack.Screen name="UserInfoSettings" component={UserInfoSettings} options={{ title: 'Settings' + headerTitle}} initialParams={{'appType':'W'}} />
        <Stack.Screen name="AcceptInvite" component={AcceptInvite} options={{ title: 'Kindly Accept Our Invite' + headerTitle}} initialParams={{'appType':'W', 'appMode':'Light'}} />
        <Stack.Screen name="Comments" component={Comments} options={{ title: 'Chat' + headerTitle}} initialParams={{'appType':'W', 'appMode':'Light'}} />

        <Stack.Screen name="SampleComponent" component={SampleComponent} options={{ title: 'SampleComponent' + headerTitle}} initialParams={{'name':'Andy', 'age':'0'}} />
        <Stack.Screen name="AboutScreen" component={AboutScreen} />
        <Stack.Screen name="ExplorePages" component={ExplorePages} options={{ title: 'Explore' + headerTitle}}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
