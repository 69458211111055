// Home.js, just a name of the stack navigation
import React, { Component } from 'react';
import { Button, Text, View } from 'react-native';
// import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";

const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "50px",
};

class Forgot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.value ? props.value : '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  clickApp() {
    console.log("Click App " + this.state.email);
    console.log("Data " + this.state);
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text>Forgot</Text>

        <div style={divStyle}>
          <TextField
            id="email"
            name="email"
            label="email"
            value={this.state.email}
            onChange={this.handleChange}
            variant="filled"
            sx={{
              color: "white",
              backgroundColor: "white",
              borderColor: "blue",
            }}
          />
          <SmartButton value="Reset" onclick={() => this.clickApp()} />
        </div>

        <p></p>

        <Button title="Go to Home" onPress={() => navigation.navigate('SignIn')} />
        <Button title="Go back" onPress={() => navigation.goBack()} />
      </View>
    );
  }
}

export default Forgot;