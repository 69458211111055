
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button, Text, View } from 'react-native';
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";
import { postPasswordApi, postDeleteAccountApi } from "../services/AuthService";

const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
  };

const darkTitleStyle = {
  fontSize: 20,
  fontWeight: 'bold',
  color: 'white'
};

const lightTitleStyle = {
  fontSize: 20,
  fontWeight: 'bold',
  color: 'black'
};

const errorStyle = {
  fontSize: 14,
  color: 'red'
};

const ManagePassword = props => {
    const [eventFromNative, setEventFromNative] = useState('initial value')
    const [appMode, setAppMode] = useState('Light');
    const [appModeColor, setAppModeColor] = useState('white');
    const [textModeColor, setTextModeColor] = useState('black');

    const [userProfileId, setUserProfileId] = useState('xyz')
    const [email, setEmail] = useState('email')
    const [token, setToken] = useState('51ae50bd-b78b-497f-ab11-cd4440402128')
    const [password, setPassword] = useState('nope')
    const [confirmPassword, setConfirmPassword] = useState('nah')
    const [accepted, setAccepted] = useState(false)
    const [responseComplete, setResponseComplete] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const stateRefUserProfileId = useRef();
    const stateRefToken = useRef();
    const stateRefPassword = useRef();
    const stateRefConfirmPassword = useRef();

    useEffect(() => {
      if (props.route.params.appMode == 'Dark') {
        setAppMode(props.route.params.appMode)
        setAppModeColor('black')
        setTextModeColor('white')
      }

        window.addEventListener('profileandtoken', profileAndTokenHandler)

        return () =>
            window.removeEventListener('profileandtoken', profileAndTokenHandler)
    }, [profileAndTokenHandler])

    const profileAndTokenHandler = useCallback(
        (e) => {
            setEventFromNative(e.detail.userProfileId)
            setUserProfileId(e.detail.userProfileId)
            setToken(e.detail.token)
            updatedToken(e.detail.token)
            stateRefUserProfileId.current = e.detail.userProfileId
            stateRefToken.current = e.detail.token
        },
        [setEventFromNative, setUserProfileId, setToken]
    )

    const updatedToken = (token) => {
        window?.webkit?.messageHandlers?.SOME_BRIDGE?.postMessage({
            message: "Updated token. " + token,
        })
    }

    const clickSetPassword = () => {
        console.log("setting password");
        console.log("password is " + stateRefPassword.current.value);
        console.log("confirm password is " + confirmPassword);
        if (stateRefPassword.current.value == stateRefConfirmPassword.current.value) {
          postPasswordApi(stateRefUserProfileId.current, stateRefPassword.current.value, stateRefToken.current)
            .then(result => {
              setResponseComplete(true);
              setResponseMessage('Successfully set password.');
              this.forceUpdate();
            })
            .catch(error => {
              setResponseComplete(true);
              setResponseMessage('Failed to set password.');
              console.log("response password error is -> ");
              console.error(error);
            });
          console.log('setting pwd: ' + password)
        } else {
          setResponseMessage('Passwords did not match.');
          console.log("Click App " + email);
        }
      }
    
    const clickDeleteAccount = () => {
        console.log('deleting uid: ' + stateRefUserProfileId.current)
        postDeleteAccountApi(stateRefUserProfileId.current, stateRefToken.current)
        .then(result => {
          var jsonResult = result[0];
          console.log('delete acct: ' + result);
          setResponseComplete(true);
          setResponseMessage('Successfully deleted account.');
        })
        .catch(error => {
          setResponseComplete(true);
          setResponseMessage('Failed to delete account.');
          console.log("response delete account error is -> ");
          console.error(error);
        });
        setResponseComplete(true);
        setResponseMessage('deleting account ' + uid);
        console.log("deleting account");
    }

    const BackButton = () => {
      if (props.route.params.appType == 'W') {
        return (<Button title="Go back" onPress={() => navigation.goBack()} />)
      }
    }

    const HomeButton = () => {
      if (props.route.params.appType == 'W') {
        return (<Button title="Go to Home" onPress={() => navigation.navigate('SignIn')} />)
      }
    }

    const ResponseText = () => {
      if (responseComplete) {
        return (<Text style={ (appMode == 'Dark') ? darkTitleStyle : lightTitleStyle }>{responseMessage}</Text>);
      }
    }

    const ErrorText = () => {
      return (<Text style={errorStyle}>{responseMessage}</Text>);
    }

    const TitleText = () => {
      if (!responseComplete) {
        return (<Text style={ (appMode == 'Dark') ? darkTitleStyle : lightTitleStyle }>Set Password or Delete Account</Text>);
      }
    }

    if (responseComplete) {
      return (
          <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: appModeColor
          }}>
            <ResponseText sx={{color: textModeColor}}/>
            <p/>
            <div>
              <BackButton />
              &nbsp;&nbsp;
              <HomeButton />
            </div>
          </View>
      )
    } else {
      return (
          <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: appModeColor
          }}>
          <TitleText />
          <p/>
          <ErrorText />
          <div style={divStyle}>
            <TextField
              id="password"
              name="password"
              label="Password"
              inputRef={stateRefPassword}
              type="password"
              autoComplete="password"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderColor: "blue",
              }}
            />
            &nbsp;&nbsp;
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              inputRef={stateRefConfirmPassword}
              type="password"
              autoComplete="current-password"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderColor: "blue",
              }}
            />
          </div>
          <p/>
          <div>
            <SmartButton value="Submit" onclick={clickSetPassword} />
            &nbsp;&nbsp;
            <SmartButton value="Delete Account" onclick={clickDeleteAccount} />
          </div>
          <p/>
          <div>
            <BackButton />
            &nbsp;&nbsp;
            <HomeButton />
          </div>
        </View>
      )
    }
}

export default ManagePassword