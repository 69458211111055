import React, { useCallback, useEffect, useState } from 'react'
import { Text } from 'react-native';
import TextField from "@mui/material/TextField";

const SampleComponent = (props) => {
    const [eventFromNative, setEventFromNative] = useState('initial value')
    const name = props.route.params.name;
    const age = props.route.params.age;

    useEffect(() => {
        window.addEventListener('customevent', customEventHandler)

        document.title = 'You landed';

        return () =>
            window.removeEventListener('customevent', customEventHandler)
    }, [])

    const customEventHandler = useCallback(
        (e) => {
            setEventFromNative(e.detail.data)
            updatedToken()
        },
        [setEventFromNative]
    )

    const updatedToken = () => {
        window?.webkit?.messageHandlers?.SOME_BRIDGE?.postMessage({
            message: "Updated token.",
        })
    }

    const onClickHandler = () => {
        window?.webkit?.messageHandlers?.SOME_BRIDGE?.postMessage({
            message: "Hello! I'm React.",
        })
    }

    return (
        <div className="sample-component">
            <div className="sample-component__card">
                <p>Name: {name}</p>
                <p>Age: {age}</p>
                <button className="sample-component__button" onClick={onClickHandler}>
                    Click me
                </button>
                <div className="sample-component__uuid">{eventFromNative}</div>
            </div>
        </div>
    )
}

export default SampleComponent