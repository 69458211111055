// Home.js, just a name of the stack navigation
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Button, Text, View } from 'react-native';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SmartButton from "../controls/SmartButton";
import {API_URL, API_ERROR_ALERT} from "../services/BaseService";

import { getSignInApi } from "../services/AuthService";


const serverURL = API_URL();

const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    pading: 10,
};

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            token: '',
            loggedIn: false,
        };
        this.handleChange = this.handleChange.bind(this);

        console.log('Server URL: ' + serverURL);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    clickSignIn() {
        console.log("clicked sign in");
        
        getSignInApi(this.state.email, this.state.password)
            .then(result => {
                console.log("Result body: " + JSON.stringify(result[0]));
                console.log("Result token: " + result[1]);
                this.state.token = result[1];
                this.state.loggedin = true;
                this.forceUpdate();
            } );
    }

    render() {
        if (this.state.loggedin) {
            return (
                 <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Text>Logged in</Text>
                </View>
            );
        } else {
            return (
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div style={divStyle}>
                        <Text>Logging in</Text>
                        <Box m={2}>
                            <TextField
                                testID={'emailTestID'}
                                id="email"
                                name="email"
                                label="email"
                                value={this.state.stretchcategory}
                                onChange={this.handleChange}
                                variant="filled"
                                defaultValue="test@sizzle.com"
                                sx={{
                                    color: "white",
                                    backgroundColor: "white",
                                    borderColor: "blue",
                                }}
                            />
                        </Box>
                        <Box m={2}>
                            <TextField
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={this.state.stretchcategory}
                                onChange={this.handleChange}
                                defaultValue="yyyyyyy"
                                sx={{
                                    color: "white",
                                    backgroundColor: "white",
                                    borderColor: "blue",
                                }}
                            />
                        </Box>
                    </div>
                    <div style={divStyle}>
                        <Box m={2}><Button title="Sign Up" onPress={() => this.props.navigation.navigate('SignUp')} /></Box>
                        <Box m={2}><Button title="Accept Invite" onPress={() => this.props.navigation.navigate('AcceptInvite')} /></Box>
                        <Box m={2}><Button title="Manage Password" onPress={() => this.props.navigation.navigate('ManagePassword')} /></Box>
                        <Box m={2}><Button title="Comments" onPress={() => this.props.navigation.navigate('Comments')} /></Box>
                        <Box m={2}><Button title="UserInfoSettings" onPress={() => this.props.navigation.navigate('UserInfoSettings')} /></Box>
                    </div>
                </View>
            );
        }
    }
}

export default SignIn;